import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enAU from "./translations/en-AU.json";
import enUS from "./translations/en-US.json";
import enCA from "./translations/en-CA.json";

i18n.use(initReactI18next).init({
    resources: {
        'en-US': { translation: enUS },
        'en-AU': { translation: enAU },
        'en-CA': { translation: enCA },
    },
    lng: "en-US", // Default language
    fallbackLng: "en-US", // Fallback language in case the selected language doesn't have translations
    interpolation: {
        escapeValue: false
    }
});

import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountryModal from "../../components/header/CountryModal";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "../../components/ScrollToTop";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { WithTracking } from "./WithTracking";

const AppLayout = ({ children }) => {
    const loading = useSelector((state) => state.loading);

    return (
        <WithTracking>
            <div className="PLT">
                <style>{`
                    body {
                        background-color: #effaff;
                    }
                `}</style>
                <CountryModal />
                <div className={"Content"}>
                    <ToastContainer position="top-center" hideProgressBar />
                    <ScrollToTop>
                        { children }
                    </ScrollToTop>
                </div>
                {loading.status && (
                    <div className="loading">
                        <FontAwesomeIcon spin style={{ fontSize: "30px" }} icon={faSpinner} />
                    </div>
                )}
            </div>
        </WithTracking>
    );
};

export default AppLayout;

import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Text } from "../../../components/common/typography/Style";
import { _url } from "../../../config/utils";
import { ColorTheme } from "../../../styles/color";
import { getSiteValueByLang } from "../../../utils/SiteUtils";
import { sideMenu } from "./util";

const RedesignFooter = () => {
    const navigate = useNavigate();
    return (
        <Grid py={"56px"} justifyContent={"center"} container bgcolor={ColorTheme.grayNeutral[800]}>
            <Grid item xs={12} md={10} px={"16px"}>
                <Grid container pb={"80px"} borderBottom={`1px solid ${ColorTheme.grayNeutral[600]}`}>
                    <Grid item xs={12} md={6}>
                        <Grid container>
                            <Grid item xs={12} mb={"40px"}>
                                <img src={_url("assets/redesign/icons/footer_logo.svg")} alt={"Logo"} />
                            </Grid>
                            <Grid item xs={12}>
                                <Text textcolor={ColorTheme.base.white} size={"sm"} textweight={"regular"}>
                                    {getSiteValueByLang("madeWithLove")}
                                </Text>
                            </Grid>
                            <Grid item xs={12} mt={"8px"}>
                                <Text textcolor={ColorTheme.base.white} size={"sm"} textweight={"regular"}>
                                    Get in touch with us at:{" "}
                                    <span
                                        onClick={() =>
                                            window.open(`mailto:${getSiteValueByLang("supportMail")}`, "_blank")
                                        }
                                        style={{ color: ColorTheme.secondary[600], cursor: "pointer" }}
                                    >
                                        {getSiteValueByLang("supportMail")}
                                    </span>
                                </Text>
                            </Grid>
                            <Grid item xs={12} mt={"8px"}>
                                <Text textcolor={ColorTheme.base.white} size={"sm"} textweight={"regular"}>
                                    If you have any questions please contact us through our chat
                                </Text>
                            </Grid>
                            <Grid item xs={12} mt={"40px"}>
                                {
                                    <img width={"100%"} src={getSiteValueByLang("footerBrands")} />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} mt={["40px", "40px", "0px"]}>
                        <Grid container columnSpacing={"40px"}>
                            {sideMenu.map((item, index) => (
                                <Grid item xs={6} md={4} key={item.id}>
                                    <Text
                                        mt={[item.id === 11 ? "24px" : "0", item.id === 11 ? "24px" : "0", "0"]}
                                        textcolor={ColorTheme.base.white}
                                        size={"md"}
                                        textweight={"bold"}
                                    >
                                        {item.title}
                                    </Text>
                                    <Box
                                        display={[
                                            item.id === 11 ? "flex" : "block",
                                            item.id === 11 ? "flex" : "block",
                                            "block"
                                        ]}
                                        gap={[item.id === 11 ? "40px" : "0", item.id === 11 ? "40px" : "0", "0"]}
                                    >
                                        {item.subMenu.map((sub) => (
                                            <Box
                                                key={sub.id}
                                                sx={{ cursor: "pointer" }}
                                                display={"flex"}
                                                mt={"16px"}
                                                gap={"12px"}
                                                onClick={() => {
                                                    if (sub.isExternalUrl) {
                                                        window.open(sub.url, "_blank");
                                                        return;
                                                    }
                                                    navigate(sub.url);
                                                }}
                                                alignItems={"center"}
                                            >
                                                {sub.icon && (
                                                    <img
                                                        style={{ height: 20, width: 20 }}
                                                        src={_url(`assets/redesign/icons/${sub.icon}`)}
                                                    />
                                                )}
                                                <Text
                                                    key={sub.id}
                                                    textcolor={ColorTheme.grayNeutral[300]}
                                                    size={"sm"}
                                                    textweight={"regular"}
                                                >
                                                    {sub.title}
                                                </Text>
                                            </Box>
                                        ))}
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Text
                    mt={"16px"}
                    size={"sm"}
                    textweight={"regular"}
                    textcolor={ColorTheme.grayNeutral[400]}
                    textAlign={"center"}
                >
                    <p>
                        © 2024 PayLater Travel. All rights reserved. <br />
                        Partnered with: <a href="http://slicepay.travel/#/for-customers">Slice Pay</a> and <a href="http://slicepay.travel/#/for-travel-agents">Slice Pay Instalments for Travel Agents</a>
                    </p>
                    <p>
                        <a href="#" onClick={event => { event.preventDefault(); window.Osano && window.Osano.cm.showDrawer(); }}>Cookie Preferences</a>
                    </p>
                </Text>
            </Grid>
        </Grid>
    );
};

export default RedesignFooter;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    setupExternalServices,
    setupExternalServicesWithUser,
} from "../AppUtils";
import { initRunDependenciesIfAllowed } from "../externaldependenciesoptout/runDependenciesIfAllowed";
import { updateSiteLang } from "../../utils/SiteUtils";


export const WithTracking = ({ children }) => {
    const user = useSelector((state) => state.auth);

    const retrieveLocalTimezone = () => {
        let timeZone = 'N/A';
        if (typeof Intl !== 'undefined' && typeof Intl.DateTimeFormat === 'function') {
            timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        return timeZone;
    }

    const initRecordOptOut = ({ localTimeZone }) => async ({ clientId, label }) => {
        await window.fetch && window.fetch(`${process.env.REACT_APP_API_URL}/register-ping?label=${label}&clientId=${clientId}&tz=${localTimeZone}`, {
            method: 'GET'
        })
    }

    const localTimeZone = retrieveLocalTimezone();
    const runDependenciesIfAllowed = initRunDependenciesIfAllowed({
        localStorage: window.localStorage,
        locationHref: window.location.href,
        recordOptOut: initRecordOptOut({ localTimeZone })
    });

    useEffect(() => {
        updateSiteLang();
        runDependenciesIfAllowed('external-services', () => {
            setupExternalServices();
        });
    }, []);

    useEffect(() => {
        runDependenciesIfAllowed('external-services-with-user', () => {
            setupExternalServicesWithUser(user);
        });
    }, [user]);

    return (
        <>
            {children}
        </>
    );
}
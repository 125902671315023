import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, STAYS_MIN_DAYS_BEFORE_BOOKING } from "../../../constants";
import { getReferrals } from "../../../redux/modules/referrals";
import { updateSearch } from "../../../redux/modules/search";
import { getHotelDestination } from "./util";
import { isSearchDateLessThanAllowed } from "./isSearchDateLessThanAllowed";

// This navigation mode works both standalone and within an iframe
// Initial reasoning was for making marketing more independent from the main app
const navigate = url => {
    window.addEventListener('message', function navigateToSearch(event) {
        if (event.origin !== window.location.origin) return;
        if (event.data.id === 'search-navigation') {
            window.removeEventListener('message', navigateToSearch);
            const relativeUrl = event.data.url;
            location.href = `${relativeUrl}`;
        }
    });
    top.postMessage({ id: 'search-navigation', url }, { targetOrigin: '*' });
};

export const useSearchForm = (props) => {
    const selectorSearchQuery = useSelector((state) => state.search.query);
    const selectorActiveTab = useSelector((state) => state.search.activeTab || 2);
    const [activeTab, setActiveTab] = useState(selectorActiveTab);
    const [searchQuery, setSearchQuery] = useState(selectorSearchQuery);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [form, setForm] = useState({
        origin: { isTouched: false },
        destination: { isTouched: false },
        departureDate: { isTouched: false },
        returnDate: { isTouched: false },
        adults: { isTouched: false }
    });

    const auth = useSelector((state) => state.auth);
    const balance = useSelector((state) => state.referrals.balance);
    const dispatch = useDispatch();
    const params = useParams();

    const updateWindowDimensions = () => setWindowWidth(window.innerWidth);

    useEffect(() => {
        props.selectedTab && setActiveTab(props.selectedTab);
    }, [props.selectedTab]);

    useEffect(() => {
        if (auth.isAuthenticated) {
            dispatch(getReferrals("flight"));
        }
        getHotelDestination().then((res) => {
            if (params.location && params.state) {
                setSearchQuery((prev) => ({
                    ...prev,
                    hotels: {
                        ...prev.hotels,
                        destination: res.find(
                            (x) =>
                                x.split("_")[0].toLowerCase() === params.location.replace("-", " ") &&
                                x.split("_")[1].replace("/", "").toLowerCase() === params.state
                        )
                    }
                }));
            }
        });
        window.addEventListener("resize", updateWindowDimensions);
        const multiCityLegsForm = [];
        if (searchQuery.flights.multiCityLegs) {
            searchQuery.flights.multiCityLegs.forEach(() => {
                multiCityLegsForm.push({
                    origin: { isTouched: false },
                    destination: { isTouched: false },
                    departureDate: { isTouched: false }
                });
            });
        }

        setForm((prev) => ({
            ...prev,
            multiCityLegs: multiCityLegsForm
        }));
    }, []);

    const changeSearchType = (type) => {
        setSearchQuery((prev) => ({
            ...prev,
            flights: {
                ...prev.flights,
                type
            }
        }));
    };

    const updateLocation = ({ field, location }) => {
        setSearchQuery((prev) => ({
            ...prev,
            flights: {
                ...prev.flights,
                [field]: location
            }
        }));
        setForm((prev) => ({
            ...prev,
            [field]: { isTouched: true }
        }));
    };

    const addMultiCityLeg = () => {
        const { multiCityLegs } = searchQuery.flights;
        if (multiCityLegs.length < 5) {
            setSearchQuery((prev) => ({
                ...prev,
                flights: {
                    ...prev.flights,
                    multiCityLegs: [
                        ...multiCityLegs,
                        {
                            origin: multiCityLegs[multiCityLegs.length - 1].destination,
                            destination: null,
                            departureDate: multiCityLegs[multiCityLegs.length - 1].departureDate || ""
                        }
                    ]
                }
            }));
            setForm((prev) => ({
                ...prev,
                multiCityLegs: [
                    ...prev.multiCityLegs,
                    {
                        origin: { isTouched: false },
                        destination: { isTouched: false },
                        departureDate: { isTouched: false }
                    }
                ]
            }));
        }
    };

    const removeMultiCityLeg = (index) => {
        const { multiCityLegs } = searchQuery.flights;
        const multiCityLegsForm = form.multiCityLegs;

        if (multiCityLegs.length > 2) {
            multiCityLegs.splice(index, 1);
            multiCityLegsForm.splice(index, 1);
            setSearchQuery((prev) => ({
                ...prev,
                flights: {
                    ...prev.flights,
                    multiCityLegs
                }
            }));
            setForm((prev) => ({
                ...prev,
                multiCityLegs: multiCityLegsForm
            }));
        }
    };

    const updateMultiCityLocation = ({ field, location, index }) => {
        const { multiCityLegs } = searchQuery.flights;
        const multiCityLegsForm = form.multiCityLegs;

        multiCityLegs[index][field] = location || null;
        multiCityLegsForm[index][field].isTouched = true;

        setSearchQuery((prev) => ({
            ...prev,
            flights: {
                ...prev.flights,
                multiCityLegs
            }
        }));
        setForm((prev) => ({
            ...prev,
            multiCityLegs: multiCityLegsForm
        }));
    };

    const updateMultiCityDepartureDate = (index, date) => {
        if (!date) return;
        const { multiCityLegs } = searchQuery.flights;
        // start looping from the selected leg
        for (let i = index; i < multiCityLegs.length; i++) {
            // on the first loop, if we aren't changing the last leg date
            if (i === index && i + 1 < multiCityLegs.length) {
                if (date <= new Date(multiCityLegs[index + 1].departureDate)) {
                    multiCityLegs[i].departureDate = moment(date).format("YYYY-MM-DD");
                    break;
                }
            }
            multiCityLegs[i].departureDate = moment(date).format("YYYY-MM-DD");
        }
        setSearchQuery((prev) => ({
            ...prev,
            flights: {
                ...prev.flights,
                multiCityLegs
            }
        }));
    };

    const onFlightSearch = () => {
        let isError = false;
        const searchQueryData = searchQuery.flights;

        const firstDepartureDate = searchQueryData.multiCityLegs[0] ? searchQueryData.multiCityLegs[0] : searchQueryData.departureDate;
        if (isSearchDateLessThanAllowed({
            todayDate: moment().format("YYYY-MM-DD"),
            flightSearchMinDaysFromToday: auth.isAuthenticated && balance > 0 ? 3 : FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY,
            searchSelectedDepartureDate: firstDepartureDate,
        })) {
            toast.error("Please select a later date for the first flight.");
            isError = true;
        }
        if (searchQueryData.type !== "multi-city") {
            const required = ["origin", "destination", "departureDate", "adults"];

            if (searchQueryData.type === "return") required.push("returnDate");
            if (searchQueryData.type === "one-way") searchQueryData.returnDate = undefined;

            required.forEach((field) => {
                if (!searchQueryData[field]) {
                    isError = true;
                    message.error(`Please select a ${field}.`);
                }
            });
        } else if (searchQueryData.type === "multi-city") {
            form.multiCityLegs.forEach((leg, index) => {
                Object.keys(form.multiCityLegs[index]).forEach((key) => {
                    if (
                        searchQueryData.multiCityLegs[index][key] === null ||
                        searchQueryData.multiCityLegs[index][key] === ""
                    ) {
                        form.multiCityLegs[index][key].isTouched = true;
                        isError = true;
                    }
                });
            });
        }
        setForm(form);

        if (!isError) {
            const {
                origin,
                destination,
                departureDate,
                returnDate,
                multiCityLegs,
                currencyCode,
                type,
                c,
                includedAirlineCodes,
                ...others
            } = searchQueryData;
            let pathname = "/flightssearch/s/";
            const multiCity = [];
            if (type === "multi-city") {
                // multi city search
                multiCityLegs.forEach((x) => {
                    multiCity.push({
                        originId: x.origin.id,
                        destinationId: x.destination.id,
                        departureDate:
                            x?.departureDate ||
                            moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, "days").format("YYYY-MM-DD")
                    });
                });
                pathname = `/flightssearch/s/${JSON.stringify(multiCity)}`;
            } else {
                // one way or return
                pathname = `/flightssearch/s/${origin.id}/${destination.id}/${departureDate}${
                    returnDate ? `/${returnDate}` : ""
                }`;
            }

            let newOthers = others;
            const usingCredit = auth.isAuthenticated && balance > 0;
            if (usingCredit) newOthers = { ...others, c: "t" };
            dispatch(updateSearch(searchQueryData));
            const href = `${pathname}?${Object.keys(newOthers)
                    .map((key) => `${key}=${newOthers[key]}`)
                    .join("&")}`
            navigate(href);

            // if edit search is active then we need to update the search
            if (props.editSearch) {
                props.editSearch(
                    {
                        ...searchQueryData,
                        c: usingCredit ? "t" : undefined
                    },
                    auth
                );
            }
        }
    };

    return {
        activeTab,
        setActiveTab,
        searchQuery,
        setSearchQuery,
        windowWidth,
        setWindowWidth,
        onFlightSearch,
        updateMultiCityDepartureDate,
        updateMultiCityLocation,
        removeMultiCityLeg,
        addMultiCityLeg,
        updateLocation,
        changeSearchType,
        balance,
        auth,
        setForm
    };
};

export const useSearchInitialValues = (searchDetail) =>
    useMemo(
        () => ({
            destination: searchDetail?.destination,
            location: searchDetail?.location || "",
            locationCode: searchDetail?.locationCode || "",
            departureDate: searchDetail
                ? moment(searchDetail.checkindate)
                : moment(new Date()).add(STAYS_MIN_DAYS_BEFORE_BOOKING, "days"),
            returnDate: searchDetail
                ? moment(searchDetail.checkoutdate)
                : moment(new Date()).add(STAYS_MIN_DAYS_BEFORE_BOOKING + 1, "days"),
            roomDetails: searchDetail?.roomDetails || [
                {
                    id: Math.random(),
                    adult: 2,
                    children: []
                }
            ]
        }),
        [searchDetail]
    );

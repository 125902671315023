import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { emptyPaymentPlan } from "../../../redux/modules/paymentPlan";
import { emptySearchResults } from "../../../redux/modules/search";
import { useHome } from "./hook";
import { Helmet } from "react-helmet";
import SearchForm from "../searchform/SearchForm";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { WithTracking } from "../../route/WithTracking";

const SearchControls = () => {
    const { selectedLocation, selectedTab, setSelectedLocation, setSelectedTab, dispatch, location } = useHome();
    const searchParams = new URLSearchParams(location.search);
    const { mobileView } = useGetWindowSize();

    useEffect(() => {
        searchParams.get("tab") === "flights" && setSelectedTab(1);
        dispatch(emptySearchResults());
        // empties the payment plan data on redux store to prevent a bug where wrong departure and payment start date data being used in the "Review” page even after the customer has selected a different search parameter in the homepage.
        dispatch(emptyPaymentPlan());
    }, [dispatch, location.pathname, setSelectedTab]);

    return (
        <WithTracking>
            <Grid container minHeight={"100vh"}>
                <Grid item xs={12}>
                    <>
                        <Helmet>
                            <title>Pay Later Travel | Search Form</title>
                        </Helmet>
                        <Grid width={"100%"} container justifyContent={"center"}>
                            <Grid item width={"100%"} maxWidth={"1140px"} pb={["0", "0", "30px"]}>
                                <SearchForm
                                    selectedTab={selectedTab}
                                    selectedLocation={selectedLocation}
                                    setSelectedTab={setSelectedTab}
                                    resetSelectedLocation={() => setSelectedLocation(null)}
                                />
                            </Grid>
                        </Grid>
                    </>
                </Grid>
            </Grid>
        </WithTracking>
    );
};

export default SearchControls;
